import React from 'react'
import { Link } from 'react-router-dom';
import { Carousel, Image, Grid } from 'antd';
import BannerBicca from '../../assets/img/banner/bicca-v3.jpg';
// import BannerGoldenTicket from '../../assets/img/banner/golden-ticket.jpg';
import MBannerBicca from '../../assets/img/banner/m-bicca-v3.jpg';
// import MBannerGoldenTicket from '../../assets/img/banner/m-golden-ticket.jpg';
// import Banner1 from '../../assets/img/candidate/banner-xpert1.png';
// import Banner2 from '../../assets/img/candidate/banner-xpert2.png';
// import Banner3 from '../../assets/img/candidate/banner-xpert3.png';
// import MBanner1 from '../../assets/img/candidate/m-banner-xpert1.png';
// import MBanner2 from '../../assets/img/candidate/m-banner-xpert2.png';
// import MBanner3 from '../../assets/img/candidate/m-banner-xpert3.png';
import Banner1 from '../../assets/img/banner/jobshub-02-v1.jpg';
import Banner2 from '../../assets/img/banner/jobshub-03-v1.jpg';
import Banner3 from '../../assets/img/banner/jobshub-04-v1.jpg';
import Banner4 from '../../assets/img/banner/jobshub-05-v1.jpg';
import MBanner1 from '../../assets/img/banner/m-jobshub-02-v1.jpg';
import MBanner2 from '../../assets/img/banner/m-jobshub-03-v1.jpg';
import MBanner3 from '../../assets/img/banner/m-jobshub-04-v1.jpg';
import MBanner4 from '../../assets/img/banner/m-jobshub-05-v1.jpg';

const { useBreakpoint } = Grid;

function GetBreakPoint() {
  const screens = useBreakpoint()
  return screens;
}

export default function newBanner() {
  const { xs, xxl } = GetBreakPoint()

  return (
    <React.Fragment>
      <Carousel dots={false} autoplay autoplaySpeed={3000}>
        <div>
          <a href='/bicca/register' target='_blank' rel="noreferrer">
            <Image
              width={'100%'}
              height={ xxl ? 460 : xs ? 160 : 350 }
              src={ xs ? MBannerBicca : BannerBicca }
              preview={false}
              style={{ objectFit: xs ? 'inherit' : 'cover' }}
            />
          </a>
        </div>
        <div>
          <Link to='/register'>
            <Image
              width={'100%'}
              height={ xxl ? 460 : xs ? 160 : 350 }
              src={ xs ? MBanner1 : Banner1 }
              preview={false}
              style={{ objectFit: xs ? 'inherit' : 'cover' }}
            />
          </Link>
        </div>
        <div>
          <a href="https://chat.whatsapp.com/K9MZPKtLy6BAZ9wq8RnvTo" target="_blank" rel="noreferrer">
            <Image
              width={'100%'}
              height={ xxl ? 460 : xs ? 160 : 350 }
              src={ xs ? MBanner2 : Banner2 }
              preview={false}
              style={{ objectFit: xs ? 'inherit' : 'cover' }}
            />
          </a>
        </div>
        <div>
          <Link to='/job/detail/nurse_in_europe'>
            <Image
              width={'100%'}
              height={ xxl ? 460 : xs ? 160 : 350 }
              src={ xs ? MBanner3 : Banner3 }
              preview={false}
              style={{ objectFit: xs ? 'inherit' : 'cover' }}
            />
          </Link>
        </div>
        <div>
          <a href="https://chat.whatsapp.com/HwETacZt11L6KiOfOCo75C" target="_blank" rel="noreferrer">
            <Image
              width={'100%'}
              height={ xxl ? 460 : xs ? 160 : 350 }
              src={ xs ? MBanner4 : Banner4 }
              preview={false}
              style={{ objectFit: xs ? 'inherit' : 'cover' }}
            />
          </a>
        </div>
      </Carousel>
    </React.Fragment>
  )
}