import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Space, Typography, Image, Card, Breadcrumb } from 'antd';
import { UserOutlined, CalendarOutlined, VideoCameraOutlined, SelectOutlined, EnvironmentOutlined } from '@ant-design/icons';
import moment from 'moment';
import 'moment/locale/id';
import Cookie from 'js-cookie';
import { withTranslation } from 'react-i18next';
import CButton from '../../Button';

const { Text } = Typography;

export function DetailEvent(props) {

  const { getEventDetail: { data }, t } = props

  return (
    <React.Fragment>
      <Row className="mt-16 mb-16">
        <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }} xxl={{ span: 16, offset: 4 }}>
          <Breadcrumb className="mb-16">
            <Breadcrumb.Item>
              <Link to={Cookie.get('user') ? '/candidate/job' : '/'}>Home</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={Cookie.get('user') ? `/candidate/job` : '/job'}>Job Opening</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={Cookie.get('user') ? `/candidate/job/detail/${data.jobSlug}` : `/job/detail/${data.jobSlug}`}>{data.jobTitle}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item className="primary-color">{data.title}</Breadcrumb.Item>
          </Breadcrumb>
          <Card className="border-radius-6 box-shadow mb-8">
            <Row className="mb-16">
              <Col span={24}>
                <Image
                  width={'100%'}
                  src={data.bannerImageUrl}
                  alt='No Image'
                  preview={false}
                  style={{ borderRadius: 6, objectFit: 'contain' }}
                />
              </Col>
            </Row>

            <Row>
              <Col span={24}>
                <Space direction='vertical' size={8}>
                  <Text className="info-color fs-24" strong>{data.title}</Text>
                  <div className="dark-color" dangerouslySetInnerHTML={{ __html: data.detail }}></div>
                </Space>
              </Col>
            </Row>

            <Row className="mt-8 mb-32">
              <Col span={24}>
                <Space direction='vertical'>
                  <Space direction='horizontal' size={12}>
                    <UserOutlined className='fs-16' />
                    <Text className="dark-color fs-16">{t('eventDetail.organizer')} <Text className="primary-color fs-16" strong>{data.organizerName}</Text></Text>

                  </Space>
                  <Space direction='horizontal' size={12}>
                    <CalendarOutlined className='fs-16' />
                    <Text className="dark-color fs-16">{data.eventDay}, {moment(data.eventDate, 'DD/MM/YYYY').format('DD MMM YYYY')} {moment(data.startTime, 'HH:mm:ss').format('HH:mm')} - {moment(data.endTime, 'HH:mm:ss').format('HH:mm')}</Text>
                  </Space>
                  <Space direction='horizontal' size={12}>
                    <VideoCameraOutlined className='fs-16' />
                    <Text className="dark-color fs-16">{data.jobEventType}</Text>
                  </Space>
                  {
                    data.eventLink ?
                      <Space direction='horizontal' size={12}>
                        <SelectOutlined className='fs-16' />
                        <Space size={4}>
                          <Text className="dark-color fs-16">{t('eventDetail.eventLinks')}</Text>
                          <a className="link-color fs-16" href={data.eventLink} target="_blank" rel="noreferrer">
                            : {data.eventLink}
                          </a>
                        </Space>
                      </Space>
                      : null
                  }
                  {
                    data.eventAddress ?
                      <Space direction='horizontal' size={12}>
                        <EnvironmentOutlined className='fs-16' />
                        <Text className="dark-color fs-16">{data.eventAddress}</Text>
                      </Space>
                      : null
                  }
                </Space>
              </Col>
            </Row>
            {
              data.eventLink ?
                <a href={data.eventLink} target="_blank" rel="noreferrer">
                  <CButton type="primary" className="float-right ml-8" title={t('eventDetail.joinTheMeeting')}/>
                  {/* <Button className='float-right ml-8' type="primary">{t('eventDetail.joinTheMeeting')}</Button> */}
                </a>
              : null
            }
            <Link to={Cookie.get('user') ? `/candidate/job/detail/${data.jobSlug}` : `/job/detail/${data.jobSlug}`}>
              <CButton type="default" className="float-right" ghost icon="ArrowLeftOutlined" title={t('eventDetail.back')}/>
              {/* <Button className='float-right' type="primary" ghost><ArrowLeftOutlined /> {t('eventDetail.back')}</Button> */}
            </Link>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default withTranslation()(DetailEvent)
