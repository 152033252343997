import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Loading } from '../../components';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";
import { withTranslation } from 'react-i18next';
import CButton from '../../components/Button';
import { indexCountry, unmountIndexCountry } from '../../redux/actions/master/country/countryAction';
import { setRegister } from '../../redux/actions/register/registerAction';
import { checkEmail, checkMobile } from '../../redux/actions/check/checkAction';
// import { Form, Input, Button, Row, Col, Card, Typography, Select, Checkbox, Divider, Tooltip, message } from 'antd';
import { Form, Input, Row, Col, Card, Typography, Select, Checkbox, Tooltip, message } from 'antd';
// import { GoogleOutlined, InfoCircleTwoTone } from '@ant-design/icons';
import { InfoCircleTwoTone } from '@ant-design/icons';
import './Register.css';
import Cookie from 'js-cookie';
// import { auth } from '../../config/firebase';
// import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';

const { Option } = Select;
const { Text } = Typography;

export class Register extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      submitLoading: false,
      idCallingCode: 99,
      callingCode: 62,
      agree: false,
      visible: false,
      uniqueEmail: false,
      uniquePhoneNumber: false,
      lang: Cookie.get('i18next')
    }
  }
  
  componentDidMount() {
    const { actionGetCountry } = this.props

    if(localStorage.getItem('link')){
      localStorage.removeItem('link')
    }
    
    if(localStorage.getItem('visible')){
      localStorage.removeItem('visible')
    }

    actionGetCountry()
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.i18n.language !== this.state.lang) {
      if(this.formRef.current.getFieldsError()[0]?.errors.length > 0){
        this.updateValidationMessages();
      }
      this.setState({ lang: Cookie.get('i18next') })
    }
  }
  
  updateValidationMessages = () => {
    const { t } = this.props

    this.formRef.current.setFields([
      {
        name: 'firstName',
        errors: [t('required')] || [t('a-zA-Z')] || [t('min',{number:3})] || [t('max',{number:25})]
      },
      {
        name: 'lastName',
        errors: [t('required')] || [t('a-zA-Z')] || [t('min',{number:3})] || [t('max',{number:25})]
      },
      {
        name: 'email',
        errors: [t('required')] || [t('typeEmail')]
      },
      {
        name: 'mobile',
        errors: [t('required')] || [ t('0-9')] || [t('typeMobileNumber')]
      },
      {
        name: 'password',
        errors: [t('required')] || [t('typePassword')] || [t('min',{number:8})]
      },
      {
        name: 'confirmPassword',
        errors: [t('required')] 
      },
      {
        name: 'agree',
        errors: [t('register.rulesAgree')]
      }
    ]);
  }

  onCheckEmail = (e) => {
    const { actionCheckEmail, t } = this.props
    return actionCheckEmail(e, response => {
      if(response){
        this.formRef.current.setFieldsValue({
          email: null
        })
        this.setState({ uniqueEmail: false })
        message.error(t('emailExist'))
      }else{
        this.setState({ uniqueEmail: true })
      }
    })
  }

  onCheckMobile = (e) => {
    const { callingCode } = this.state
    const { actionCheckMobile, t } = this.props

    this.formRef.current.setFieldsValue({
      mobile: e.replace(/^0+/, '')
    })

    e = `${callingCode}${e.replace(/^0+/, '')}`
    
    return actionCheckMobile(e, response => {
      if(response){
        this.formRef.current.setFieldsValue({
          mobile: null
        })
        this.setState({ uniquePhoneNumber: false })
        message.error(t('mobileNumberExist'))
      }else{
        this.setState({ uniquePhoneNumber: true })
      }
    })
  }

  onFinish = (values) => {
    const { idCallingCode, callingCode, uniqueEmail, uniquePhoneNumber } = this.state
    const { actionSetRegister } = this.props
    
    if(uniqueEmail && uniquePhoneNumber){
      values.callingCodeId = idCallingCode
      values.mobile = `${callingCode}${values.mobile}`
      
      return this.setState({ submitLoading: true }, () => {
        return actionSetRegister(values, response => {
          if(response.code === '1000'){
            return this.setState({ submitLoading: false }, () => {
              localStorage.setItem("email", values.email)
              localStorage.setItem("password", values.password)
  
              this.props.history.push('/register/confirm-email')
            })
          }else{
            message.error(response.message)
          }
        }, (err) => {
          return this.setState({ submitLoading: false }, () => message.error(err.message))
        })
      })
    }else{
      this.onCheckEmail(this?.formRef?.current?.getFieldValue()?.email)
    }
  }

  // googleLogin = () => {
  //   const provider = new GoogleAuthProvider();
  //   signInWithPopup(auth, provider).then(async (result) => {
  //     console.log(1, result);
  //   })
  // }

  onChangeCheck = (e) => {
    this.setState({
      agree: e.target.checked
    })
  }

  onChangeCode = (value, option) => {
    this.setState({
      callingCode: Number(option.text),
      idCallingCode: Number(option.key)
    })
    this.formRef.current.setFieldsValue({
      mobile: null
    })
  }

  onFocusPassword = (value) => {
    this.setState({
      visible: value
    })
  }
  
  render() {
    const { agree, submitLoading, visible } = this.state
    const { getCountry: { loading, data }, t } = this.props
    if(process.env.REACT_APP_ENV === "production"){
      ReactGA.pageview(`${window.location.pathname}${window.location.search}`, [], 'Jobshub - Register');
    }
    
    if(loading){
      return <Loading />
    }

    const prefixSelector = (
      <Form.Item name="code" noStyle>
        <Select onChange={this.onChangeCode} showSearch>
          {
            data?.map(item =>
              <Option text={item.callingCode} key={item.id} value={`+${item.callingCode}`}>{`+${item.callingCode}`}</Option>
            )
          }
        </Select>
      </Form.Item>
    )

    return (
      <React.Fragment>
        <Helmet>
          <title>Jobshub - Register</title>
        </Helmet>
        <Row className="display-flex" align="middle" justify="center">
          <Col>
            <Card className="card-register" bodyStyle={{ padding: 0, display: 'flex' }}>
              <Form 
                ref={this.formRef}
                initialValues={{
                  code: '+62'
                }}
                onFinish={this.onFinish}
              >
                <Row gutter={8}>
                  <Col className="fs-28" xs={24} sm={24} md={24}>
                    <Text type="secondary">{t('register.title')}</Text>
                  </Col>

                  <Col className="fs-13 mb-16" xs={24} sm={24} md={24}>
                    <Text type="secondary">{t('register.subTitle')}</Text>
                  </Col>

                  <Col xs={24} sm={12}>
                    <Form.Item 
                      className="mb-16"
                      name="firstName" 
                      validateFirst
                      rules={[
                        { required: true, message: t('required') },
                        { pattern: /^[a-zA-Z ]*$/, message: t('a-zA-Z') },
                        { min: 3, message: t('min',{number:3}) },
                        { max: 25, message: t('max',{number:25}) }
                      ]}
                    >
                      <Input placeholder={t('register.firstName')} />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={12}>
                    <Form.Item 
                      className="mb-16"
                      name="lastName" 
                      validateFirst
                      rules={[
                        { required: true, message: t('required') },
                        { pattern: /^[a-zA-Z ]*$/, message: t('a-zA-Z') },
                        { min: 3, message: t('min',{number:3}) },
                        { max: 25, message: t('max',{number:25}) }
                      ]}
                    >
                      <Input placeholder={t('register.lastName')} />
                    </Form.Item>
                  </Col>
                  
                  <Col xs={24} sm={24} md={12}>
                    <Form.Item 
                      className="mb-16"
                      name="email"
                      validateFirst
                      rules={[
                        { required: true, message: t('required') },
                        { type: 'email', message: t('typeEmail') },
                        // Hanya untuk di production jika di staging akan di matikan
                        // { pattern:  /.+@(gmail|yahoo)\.com$/, message: 'Preferred using Gmail or Yahoo' }
                      ]}
                    >
                      <Input type="email" onBlur={(e) => this.onCheckEmail(e.target.value)} placeholder={t('register.email')} />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={12}>
                    <Form.Item 
                      className="mb-16"
                      name="mobile"
                      validateFirst
                      rules={[
                        { required: true, message: t('required') },
                        { pattern: /^[0-9]*$/, message: t('0-9') },
                        { min: 7, max: 13, message: t('typeMobileNumber') },
                        // { validator(rule, value, callback){
                        //     if(idCallingCode === 99)
                        //       if(value.charAt(0) === '0' || value.charAt(0) === '8' || value.charAt(0) === ''){
                        //         callback()
                        //       }else{
                        //         callback('Invalid phone number')
                        //       }
                        //   }
                        // }
                      ]}
                    >
                      <Input onBlur={(e) => this.onCheckMobile(e.target.value)} addonBefore={prefixSelector} placeholder={t('register.mobileNumber')} />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={12}>
                    <Form.Item 
                      className="mb-8"
                      name="password"
                      validateFirst
                      hasFeedback
                      rules={[
                        { required: true, message: t('required') },
                        { pattern: /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/, message: t('typePassword') },
                        { min: 8, message: t('min',{number:8}) },
                      ]}
                    >
                      <Input.Password onFocus={() => this.onFocusPassword(true)} onBlur={() => this.onFocusPassword(false)} placeholder={t('register.password')} />
                    </Form.Item>
                  </Col>
                  
                  <Col xs={22} sm={22} md={11}>
                    <Form.Item 
                      className="mb-8"
                      name="confirmPassword" 
                      dependencies={['password']}
                      hasFeedback
                      rules={[
                        { required: true, message: t('required') },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(new Error(t('typeConfirmPassword')));
                          },
                        }),
                      ]}
                    >
                      <Input.Password placeholder={t('register.confirmPassword')} />
                    </Form.Item>
                  </Col>

                  <Col className="pt-4 dark-color pl-0" xs={2} sm={2} md={1}>
                    <Tooltip visible={visible} placement="right" title={t('register.tooltip')}>
                      <InfoCircleTwoTone onMouseLeave={() => this.onFocusPassword(false)} onMouseEnter={() => this.onFocusPassword(true)} twoToneColor="#0076de" />
                    </Tooltip>
                  </Col>

                  <Col xs={24} sm={24} md={24}>
                    <Form.Item 
                      className="mb-8"
                      name="agree"
                      rules={[
                        { 
                          validator(rule, value, callback){
                            if(!agree){
                              callback(t('register.rulesAgree'))
                            }else{
                              callback()
                            }
                          }
                        }
                      ]}
                    >
                      <Checkbox className="fs-11 dark-color" onChange={this.onChangeCheck} checked={agree}>
                        {t('register.checkbox')}
                        <Link className="info-color" to="/terms-and-conditions" target="_blank"> {t('register.termsAndCondition')} </Link>
                        {t('register.and')}
                        <Link className="info-color" to="/privacy-policy" target="_blank"> {t('register.privacyPolicy')} </Link>
                      </Checkbox>
                    </Form.Item>
                  </Col>
                  
                  <Col xs={24} sm={24} md={24}>
                    <Form.Item className="mb-8">
                      <CButton type="primary" title={t('register.button')} className="width-100" htmlType="submit" loading={submitLoading}/>
                    </Form.Item>
                  </Col>
                  
                  <Col xs={24} sm={24} md={24}>
                    <Text className="fs-11" type="secondary">{t('register.subButton')} <Link className="info-color" to="/login">{t('register.login')}</Link> </Text>
                  </Col>
                  
                  {/* <Col xs={24} sm={24} md={24}>
                    <Divider className="fs-11 dark-color mb-16 mt-8" plain>{t('register.divider')}</Divider>
                  </Col> */}

                  {/* <Col xs={24} sm={24} md={12}> */}
                  {/* <Col xs={24} sm={24} md={24}>
                    <Form.Item className="mb-8">
                      <Button 
                        className="button-google dark-color border-radius-6" 
                        block 
                        onClick={this.googleLogin}
                      >
                        <GoogleOutlined /> {t('register.btnRegisterGoogle')}
                      </Button>
                    </Form.Item>
                  </Col> */}

                  {/* <Col xs={24} sm={24} md={12}>
                    <Form.Item className="mb-8">
                      <Button 
                        className="button-facebook dark-color border-radius-6" 
                        block 
                        onClick={this.facebookLogin}
                      >
                        <FacebookFilled /> Facebook
                      </Button>
                    </Form.Item>
                  </Col> */}
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
  componentWillUnmount(){
    const { unmountIndexCountry } = this.props
    return unmountIndexCountry()
  }
}

const mapStateToProps = (state) => ({
  getCountry: state.countryReducer
})

const mapDispatchToProps = {
  actionGetCountry: indexCountry,
  unmountIndexCountry: unmountIndexCountry,
  actionCheckEmail: checkEmail,
  actionCheckMobile: checkMobile,
  actionSetRegister: setRegister
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Register))